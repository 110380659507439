import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';

import PageBreaker from 'components/PageBreaker';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IMeltletsIntroProps } from './models';
import './MeltletsIntro.scss';

const MeltletsIntro: FC<IMeltletsIntroProps> = ({ meltletsIntro }) => {
  const {
    properties: { text, image, imageAlt },
  } = meltletsIntro[0];

  return (
    <div data-test="MeltletsIntro" className="meltlets-intro">
      <div className="meltlets-intro__container">
        <Container>
          <DangerouslySetInnerHtml html={text} className="meltlets-intro__text" />
        </Container>
      </div>
      {image ? (
        <div className="meltlets-intro__image-wrapper">
          <PageBreaker img={image} imgAlt={imageAlt} />
        </div>
      ) : null}
    </div>
  );
};

export const query = graphql`
  fragment FragmentMeltletsIntro on TMeltletsIntro {
    properties {
      image {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobileImage: gatsbyImage {
          childImageSharp {
            fluid(maxHeight: 1000) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        focalPoint {
          x
          y
        }
      }
      text
      imageAlt
    }
  }
`;

export default MeltletsIntro;
