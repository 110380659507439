import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';

import UniversalImage from 'components/common/UniversalImage';

import { IMeltletsAnimationProps } from './models';
import './MeltletsAnimation.scss';

const MeltletsAnimation: FC<IMeltletsAnimationProps> = ({ meltletsAnimation }) => {
  const {
    properties: { image, imageAlt, title },
  } = meltletsAnimation[0];

  return (
    <div data-test="MeltletsAnimation" className="meltlets-animation">
      <Container>
        <h2 className="meltlets-animation__title">{title}</h2>
      </Container>

      <div className="meltlets-animation__content">
        {image?.gatsbyImage?.childImageSharp ? (
          <div className="meltlets-animation__image-wrapper">
            <UniversalImage
              imageAlt={imageAlt}
              img={image}
              wrapperClassName="meltlets-animation__image"
            />
          </div>
        ) : (
          <div className="meltlets-animation__image-wrapper">
            <img
              src={image.fallbackUrl}
              alt={imageAlt}
              width="450"
              height="450"
              className="meltlets-animation__image"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentMeltletsAnimation on TMeltletsAnimation {
    properties {
      title
      image {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobileImage: gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      imageAlt
    }
  }
`;

export default MeltletsAnimation;
