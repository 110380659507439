import React, { FC } from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"

import DangerouslySetInnerHtml from "gatsby-theme-nurofen/src/components/common/DangerouslySetInnerHtml"

import UniversalImage from "gatsby-theme-nurofen/src/components/common/UniversalImage"

import { IMeltletsProductProps } from "gatsby-theme-nurofen/src/components/MeltletsProduct/models"
import "gatsby-theme-nurofen/src/components/MeltletsProduct/MeltletsProduct.scss"
import "./MeltletsProductOverride.scss"
import MeltletsProductCard from "./MeltletsProductCard"

const MeltletsProduct: FC<IMeltletsProductProps> = ({
  meltletsProduct,
  selectedProduct,
  carouselControls,
}) => {
  const {
    properties: { sectionTitle, text },
  } = meltletsProduct[0]

  return (
    <div data-test="MeltletsProduct" className="meltlets-product">
      <Container className="meltlets-product__container">
        <h2 className="meltlets-product__title">{sectionTitle}</h2>
        <div className="meltlets-product__cards">
          {selectedProduct.nodes.map((product, index) => (
            <MeltletsProductCard
              product={product}
              index={index}
              meltletsProduct={meltletsProduct[index]}
              carouselControls={carouselControls}
            />
          ))}
        </div>

        <DangerouslySetInnerHtml
          html={text}
          className="meltlets-product__text"
        />
      </Container>
    </div>
  )
}

export const query = graphql`
  fragment FragmentMeltletsProduct on TMeltletsProduct {
    properties {
      sectionTitle
      title
      text
      productImageAlt
      relatedElementsTrigger {
        properties {
          ...FragmentRelatedElementsTrigger
        }
      }
      relatedElements {
        properties {
          ...FragmentRelatedElementsList
        }
      }
    }
  }
`

export default MeltletsProduct
