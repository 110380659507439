import React, { FC } from 'react';
import { graphql } from 'gatsby';

import UniversalImage from 'components/common/UniversalImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { IMeltletsTasteProps } from './models';
import './MeltletsTaste.scss';

const MeltletsTaste: FC<IMeltletsTasteProps> = ({ meltletsTaste }) => {
  const {
    properties: { image, imageAlt, infoList, title },
  } = meltletsTaste[0];

  const { items } = infoList[0].properties;

  return (
    <div data-test="MeltletsTaste" className="meltlets-taste">
      <h2 className="meltlets-taste__title">{title}</h2>
      <div className="meltlets-taste__content">
        {items?.length ? (
          <ul className="meltlets-taste__list">
            {items.map(({ properties: { infoCardImageAlt, infoCardImage, infoCardText } }) => (
              <li key={infoCardText}>
                <DangerouslySetInnerHtml
                  html={infoCardText}
                  className="meltlets-taste__list-item-text"
                />
                <UniversalImage
                  img={infoCardImage}
                  imageAlt={infoCardImageAlt}
                  wrapperClassName="meltlets-taste__list-item-image"
                />
              </li>
            ))}
          </ul>
        ) : null}
        {image ? (
          <div className="meltlets-taste__image-wrapper">
            <UniversalImage
              imageAlt={imageAlt}
              img={image}
              wrapperClassName="meltlets-taste__image"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentInfoCard on TInfoCard {
    properties {
      infoCardImage {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        svg {
          content
        }
      }
      infoCardImageAlt
      infoCardText
    }
  }

  fragment FragmentMeltletsTaste on TMeltletsTaste {
    properties {
      title
      infoList {
        properties {
          items {
            ...FragmentInfoCard
          }
        }
      }
      image {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxHeight: 260, maxWidth: 460) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobileImage: gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      imageAlt
    }
  }
`;

export default MeltletsTaste;
