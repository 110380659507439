import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';

import { IReferencesProps } from './models';
import './References.scss';

const References: FC<IReferencesProps> = ({ references }) => {
  const {
    properties: { title, items },
  } = references[0];

  return (
    <div data-test="References" className="references">
      <Container className="references__container">
        <h2 className="references__title">{title}</h2>
        <ol>
          {items.map(({ properties: { text } }) => (
            <li key={text}>{text}</li>
          ))}
        </ol>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentReferences on TReferences {
    properties {
      title
      items {
        properties {
          text
        }
      }
    }
  }
`;

export default References;
