import React, { useCallback, useEffect, useMemo, useState } from "react"
import classNames from "classnames"

import UniversalImage from "gatsby-theme-nurofen/src/components/common/UniversalImage"
import RelatedElementsCarousel, {
  IRelatedElementsCarouselCalculated,
  IRelatedElementsCarouselData,
} from "gatsby-theme-nurofen/src/components/RelatedElementsCarousel"
import Helpers from "gatsby-theme-nurofen/src/components/MeltletsProduct/helpers"
import SimplePopup from "gatsby-theme-nurofen/src/components/SimplePopup"
import RelatedElementsList from "gatsby-theme-nurofen/src/components/RelatedElementsList"
import { parseNFButton } from "gatsby-theme-nurofen/src/components/common/NFButton/parsers"
import NFButton from "gatsby-theme-nurofen/src/components/common/NFButton"

import {
  WindowPadding,
  RelatedElementsTriggerId,
  DefaultItemWidth,
  MobileViewRelatedElements,
} from "gatsby-theme-nurofen/src/components/MeltletsProduct/constants"
import useScreenRecognition from "gatsby-theme-nurofen/src/hooks/useScreenRecognition"
import { gtmService } from "gatsby-theme-nurofen/src/services/gtmService"

const MeltletsProductCard = ({
  product,
  meltletsProduct,
  index,
  carouselControls,
}) => {
  const {
    properties: { relatedElementsTrigger, relatedElements },
  } = meltletsProduct
  const [
    isShowScrollOnRelatedElements,
    setShowScrollOnRelatedElements,
  ] = useState<boolean>(false)
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false)
  const popupListProps: IRelatedElementsCarouselData | undefined = useMemo(
    () =>
      Helpers.getRelatedElementsListProps(relatedElements, carouselControls),
    []
  )
  const [
    responsiveObj,
    setResponsiveObj,
  ] = useState<IRelatedElementsCarouselCalculated>({
    desktop: 0,
  })
  const {
    isDesktop,
    isLargeDesktop,
    isMobile,
    windowWidth,
  } = useScreenRecognition()

  const handleCalculatedResponsiveObj = useCallback(
    (isDesktopMode: boolean | null, currentWidth: number) => () => {
      if (isDesktopMode) {
        setResponsiveObj({
          desktop: Math.floor(
            (currentWidth - WindowPadding) / DefaultItemWidth
          ),
        })
      }
    },
    []
  )

  const handleRelatedElementsVisibilityChange = useCallback(() => {
    setPopupVisible((oldValue: boolean) => {
      return !oldValue
    })
  }, [])

  const popupTriggerData = relatedElementsTrigger[0].properties
  const popupTriggerBtnData = popupTriggerData?.relatedElementsTriggerCTA?.[0]
    ?.properties
    ? parseNFButton(
        popupTriggerData?.relatedElementsTriggerCTA?.[0]?.properties
      )
    : undefined
  const popupTriggerIcon = popupTriggerData?.relatedElementsTriggerCTAIcon
  const popupTriggerActiveText =
    popupTriggerData?.relatedElementsTriggerActiveText

  const handleResizePopupList = useCallback(() => {
    Helpers.resizeRelatedElementsList(
      isMobile,
      setShowScrollOnRelatedElements,
      popupListProps?.items.length
    )
  }, [popupListProps, isMobile])

  useEffect(() => {
    if (isPopupVisible) {
      handleResizePopupList()
    }
  }, [isPopupVisible, windowWidth, handleResizePopupList])

  useEffect(() => {
    handleCalculatedResponsiveObj(isDesktop || isLargeDesktop, windowWidth)()
  }, [windowWidth, isDesktop, isLargeDesktop])

  useEffect(() => {
    gtmService.emitProductView(product)
  }, [])

  const handleRetailerClick = useCallback(
    productData => (shopName: string) => {
      gtmService.emitRetailerClick(productData, shopName)
    },
    []
  )

  return (
    <div className="meltlets-product__card" key={index}>
      <h3 className="meltlets-product__card-title">
        {product.defaultProductTitle}
      </h3>
      {product.defaultProductImage?.gatsbyImage && (
        <UniversalImage
          img={product.defaultProductImage}
          imageAlt={product.productImageAlt}
          wrapperClassName="nf-card__img type--img"
          imgStyle={{ objectFit: "contain" }}
          objectFitData={{ objectFit: "contain" }}
          withDirectionClassName
          isLazyLoading
        />
      )}
      {popupTriggerBtnData ? (
        <div className="meltlets-product__card-btn">
          <NFButton
            {...popupTriggerBtnData}
            className="related-map-view-btn related-elements-trigger"
            icon={popupTriggerIcon}
            onClick={handleRelatedElementsVisibilityChange}
            customElement={
              isPopupVisible ? <span className="trigger-triangle" /> : null
            }
            btnText={
              isPopupVisible
                ? popupTriggerActiveText
                : popupTriggerBtnData.btnText
            }
            btnStyles={Helpers.getButtonStyles(
              isPopupVisible,
              popupTriggerBtnData
            )}
            id={RelatedElementsTriggerId}
          />
        </div>
      ) : null}
      {popupListProps && isPopupVisible ? (
        <SimplePopup
          className={classNames("related-elements-popup", {
            "with-scroll": isShowScrollOnRelatedElements,
          })}
          closeButtonTitle={popupListProps.closeButtonTitle}
          closeButtonAriaLabel={popupListProps.closeButtonAriaLabel}
          handleVisibilityChange={handleRelatedElementsVisibilityChange}
          clickOutsideExceptionsIds={[RelatedElementsTriggerId]}
          withCloseBtn
        >
          {windowWidth <= MobileViewRelatedElements ? (
            <RelatedElementsList
              {...popupListProps}
              retailerClickHandler={handleRetailerClick(product)}
            />
          ) : (
            <RelatedElementsCarousel
              {...popupListProps}
              responsiveObjCalculated={responsiveObj}
              retailerClickHandler={handleRetailerClick(product)}
            />
          )}
        </SimplePopup>
      ) : null}
    </div>
  )
}

export default MeltletsProductCard
