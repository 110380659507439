import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import MeltletsIntro from 'components/MeltletsIntro';
import References from 'components/References';
import MeltletsProduct from 'components/MeltletsProduct';
import MeltletsTaste from 'components/MeltletsTaste';
import Banner from 'components/Banner';
import { parseBanner } from 'components/Banner/parsers';
import MeltletsAnimation from 'components/MeltletsAnimation';

import { IUmbracoMeltletsLandingPage } from './models';
import './MeltletsLandingPage.scss';
import './MeltletsLandingPageOverride.scss';

const MeltletsLandingPage: FC<IUmbracoMeltletsLandingPage> = ({
  data,
  pageContext: { breadcrumbs },
}) => {
  const {
    footer,
    header,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    carouselControls,
    pageLangs: { nodes: langs },
    selectedProduct,
    legalPopup,
    meltletsPage: {
      lang,
      seoMetaTitle,
      seoMetaKeywords,
      seoMetaDescription,
      seoExternalHreflangs,
      ogImage,
      pageBanner,
      bannerLogoImage,
      meltletsIntro,
      references,
      meltletsProduct,
      meltletsTaste,
      meltletsAnimation,
    },
  } = data;

  return (
    <Layout
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <div className="meltlets-page">
        <Banner
          {...parseBanner(pageBanner[0].properties)}
          logo=""
          imgSecond={bannerLogoImage?.[0]?.properties?.pageBannerLogo}
          imageSecondAlt={pageBanner?.[0]?.properties?.pageBannerImageAlt || ''}
          breadcrumbs={breadcrumbs}
        />
        <MeltletsIntro meltletsIntro={meltletsIntro} />
        <MeltletsAnimation meltletsAnimation={meltletsAnimation} />
        <MeltletsTaste meltletsTaste={meltletsTaste} />
        <MeltletsProduct
          meltletsProduct={meltletsProduct}
          carouselControls={carouselControls}
          selectedProduct={selectedProduct}
        />
        <References references={references} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $link: String!
    $pageIdRegex: String
    $selectedProduct: [String]
    $pageBannerImageSize: Int = 1000
  ) {
    meltletsPage(link: { eq: $link }, lang: { eq: $lang }) {
      link
      lang
      pageBanner {
        properties {
          ...FragmentBanner
        }
      }
      bannerLogoImage: pageBanner {
        properties {
          pageBannerLogo {
            fallbackUrl
            url
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            svg {
              content
            }
          }
        }
      }
      meltletsIntro {
        ...FragmentMeltletsIntro
      }
      references {
        ...FragmentReferences
      }
      meltletsProduct {
        ...FragmentMeltletsProduct
      }
      meltletsTaste {
        ...FragmentMeltletsTaste
      }
      meltletsAnimation {
        ...FragmentMeltletsAnimation
      }
      ...FragmentSeo
    }
    selectedProduct: allUmbracoProduct(
      filter: { link: { in: $selectedProduct }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    pageLangs: allMeltletsPage(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default MeltletsLandingPage;
